<template>
  <div id="setup-hfa-portal">
    <!-- LICENSING NEEDS -->
    <div
      class="step"
      id="licensing-needs"
      v-show="currentStep == 'licensing-needs'"
    >
      <FormHeader
        title="Application for HFA Mechanical Licensing Account"
        @goBack="back()"
      />

      <div class="body">
        <div class="item">
          <strong>What are your licensing needs?</strong>

          <ul>
            <li>
              <label class="radio" for="one-time-project">
                <input
                  type="radio"
                  id="one-time-project"
                  v-model="user.projectType"
                  value="one-time-project"
                  @click="goTo('songfile-account')"
                />
                <div class="square"></div>
                <span>I'm getting a license for a onetime project</span>
              </label>
            </li>
            <li>
              <label class="radio" for="ongoing-project">
                <input
                  type="radio"
                  id="ongoing-project"
                  v-model="user.projectType"
                  value="ongoing-project"
                />
                <div class="square"></div>
                <span>I need an ongoing licensing relationship</span>
              </label>
            </li>
          </ul>
        </div>

        <transition name="fade">
          <div class="item" v-show="user.projectType == 'ongoing-project'">
            <strong>How many units do you anticipate to manufacture?</strong>

            <ul>
              <li>
                <label class="radio" for="over-2k">
                  <input
                    type="radio"
                    id="over-2k"
                    v-model="user.unitsCount"
                    value="over-2k"
                    @click="goTo('license-owner')"
                  />
                  <div class="square"></div>
                  <span>Over 2500</span>
                </label>
              </li>
              <li>
                <label class="radio" for="less-2k">
                  <input
                    type="radio"
                    id="less-2k"
                    v-model="user.unitsCount"
                    value="less-2k"
                    @click="goTo('songfile-account')"
                  />
                  <div class="square"></div>
                  <span>Under 2500</span>
                </label>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>

    <!-- SONGFILE ACCOUNT -->
    <div
      class="step"
      id="songfile-account"
      v-show="currentStep == 'songfile-account'"
    >
      <FormHeader
        title="Application for HFA Mechanical Licensing Account"
        @goBack="back()"
      />

      <div class="body">
        <div class="item">
          <strong>Nature of Licensing Needs:</strong>
          <ul>
            <li>
              <label class="radio">
                <input type="radio" checked="checked" />
                <div class="square"></div>
                <span v-if="user.projectType == 'one-time-project'"
                  >Onetime project</span
                >
                <span v-if="user.projectType != 'one-time-project'"
                  >Under 2500 units</span
                >
              </label>
            </li>
          </ul>
        </div>
        <div class="item">
          <strong
            >Get your licenses from
            <a href="http://www.songfile.com" target="_blank">Songfile</a>
            today!</strong
          >
          <a
            href="http://www.songfile.com"
            target="_blank"
            class="button primary shadow"
            >Set up Songfile account</a
          >
        </div>
      </div>
    </div>

    <!-- LICENSE OWNER -->
    <div
      class="step"
      id="license-owner"
      v-show="currentStep == 'license-owner'"
    >
      <FormHeader
        title="Application for HFA Mechanical Licensing Account"
        @goBack="back()"
      />

      <div class="body">
        <div class="loading" v-if="isLoading">
          <span>Loading</span>
          <img src="@/assets/loading.svg" alt="loading" />
        </div>
        <div class="buttons">
          <a
            class="button"
            @click="user.obo = 'myself'"
            :class="{
              primary: user.obo == 'myself',
              default: user.obo != 'myself'
            }"
            >I'm Licensing on my behalf</a
          >
          <a
            class="button"
            @click="user.obo = '3rd party'"
            :class="{
              primary: user.obo == '3rd party',
              default: user.obo != '3rd party'
            }"
            >I'm Licensing on behalf of someone else (as a third party
            administrator)</a
          >
        </div>

        <div class="type">
          <span v-show="user.obo == 'myself'">(me)</span>
          <span v-show="user.obo == '3rd party'">(3rd party's info)</span>
        </div>
        <transition name="fade">
          <form
            @submit.stop.prevent="submitForm"
            id="license-form"
            novalidate
            v-show="user.obo != null"
          >
            <div class="column">
              <div class="form-group">
                <label for="email">Email Address*</label>
                <InputField
                  name="email"
                  :value="user.info.email"
                  @update:value="user.info.email = $event"
                  label="Email address"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="companyName">Company Name*</label>
                <InputField
                  name="companyName"
                  :value="user.info.companyName"
                  @update:value="user.info.companyName = $event"
                  label="Company Name"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="contactPerson">Contact Person*</label>
                <InputField
                  name="contactPerson"
                  :value="user.info.contactPerson"
                  @update:value="user.info.contactPerson = $event"
                  label="Contact Person"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="phone">Primary Phone*</label>
                <InputField
                  name="phone"
                  mask="###-###-####"
                  :value="user.info.phone"
                  @update:value="user.info.phone = $event"
                  label="Primary Phone"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="phone2">Secondary Phone or Fax</label>
                <InputField
                  name="phone2"
                  mask="###-###-####"
                  :value="user.info.phone2"
                  @update:value="user.info.phone2 = $event"
                  label="Secondary Phone or Fax"
                  :required="false"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="hfaAccount">Existing HFA Account Number</label>
                <InputField
                  name="hfaAccount"
                  :value="user.info.hfaAccount"
                  @update:value="user.info.hfaAccount = $event"
                  label="Existing HFA Account Number"
                  :required="false"
                  :errors="errors"
                />
              </div>
            </div>
            <div class="column">
              <div class="form-group">
                <label for="address">Address*</label>
                <InputField
                  name="address"
                  :value="user.info.address"
                  @update:value="user.info.address = $event"
                  label="Address"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="city">City*</label>
                <InputField
                  name="city"
                  :value="user.info.city"
                  @update:value="user.info.city = $event"
                  label="City"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="state">State/Province/Region*</label>
                <InputField
                  name="state"
                  :value="user.info.state"
                  @update:value="user.info.state = $event"
                  label="State/Province/Region"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="country">Country*</label>
                <InputField
                  name="country"
                  :value="user.info.country"
                  @update:value="user.info.country = $event"
                  label="Country"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div class="form-group">
                <label for="zip">Postal Code*</label>
                <InputField
                  name="zip"
                  mask="#####"
                  :value="user.info.zip"
                  @update:value="user.info.zip = $event"
                  label="Postal Code"
                  :required="true"
                  :errors="errors"
                />
              </div>
            </div>
            <div class="submit">
              <button class="button primary shadow" type="submit">
                Submit
              </button>
            </div>
          </form>
        </transition>
      </div>
    </div>

    <!-- REGISTER SONG COMPLETE -->
    <div class="step" id="complete" v-show="currentStep == 'complete'">
      <FormHeader
        title="Application for HFA Mechanical Licensing Account"
        @goBack="back()"
      />

      <div class="body">
        <div class="message">
          Thank you for completing your HFA mechanical licensing application.
          Your application is being processed and you will hear from a
          representative in 10 business days.
        </div>

        <router-link :to="{ name: 'home' }" class="button primary shadow"
          >Back to home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormHeader from "@/components/FormHeader.vue";
import InputField from "@/components/InputField.vue";

let noscroll = function() {
  window.scrollTo(0, 0);
};

export default {
  name: "SetupHfaPortal",
  props: ["holderName"],
  components: {
    FormHeader,
    InputField
  },
  metaInfo: function() {
    return {
      title: " - HFA Mechanical Licensing",
      meta: [
        {
          name: "description",
          content: "Application for HFA Mechanical Licensing Account"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      isLoading: false,
      isSuccess: false,
      user: {
        projectType: null,
        unitsCount: null,
        obo: null,
        info: {
          companyName: null,
          contactPerson: null,
          address: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          phone: null,
          phone2: null,
          email: null,
          hfaAccount: null
        }
      },
      errors: [],
      currentStep: "licensing-needs",
      steps: ["licensing-needs"]
    };
  },
  watch: {
    currentStep: function(current) {
      // let oldStep = this.$el.querySelector(`#${original}`);
      let newStep = this.$el.querySelector(`#${current}`);

      newStep.classList.add("active");
    }
  },
  methods: {
    goTo: function(step) {
      this.currentStep = step;
      this.steps.push(step);
    },
    back: function() {
      if (this.steps.length < 2) {
        this.user.projectType = null;
        this.user.unitsCount = null;
        this.$router.go(-1);

        return false;
      }

      let left = this.steps.splice(-2);
      this.$el.querySelector(`#${left[1]}`).classList.remove("active");

      this.goTo(left[0]);
    },
    submitForm: function() {
      let requiredFields = [
        "email",
        "companyName",
        "contactPerson",
        "phone",
        "address",
        "city",
        "state",
        "country",
        "zip"
      ];
      this.errors = [];

      for (let key in this.user.info) {
        let value = this.user.info[key];

        if (requiredFields.includes(key) && !value) {
          this.errors.push({ field: key, error: "Field Required" });
        }
        if (
          key == "email" &&
          !this.errors.find(error => error.field == "email")
        ) {
          let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!reg.test(value)) {
            this.errors.push({ field: key, error: "Email is invalid" });
          }
        }
      }

      if (!this.errors.length) {
        this.isLoading = true;
        let $this = this;

        let payload = {
          companyName: this.user.info.companyName,
          contactPerson: this.user.info.contactPerson,
          email: this.user.info.email,
          address: this.user.info.address,
          city: this.user.info.city,
          state: this.user.info.state,
          zip: this.user.info.zip,
          country: this.user.info.country,
          phone: this.user.info.phone,
          obo: this.user.obo,
          phone2: this.user.info.phone2,
          hfaAccount: this.user.info.hfaAccount
        };
        let url = process.env.VUE_APP_API_URL.replace(
          "users",
          "mechanical_contact"
        );

        axios
          .post(url, payload)
          .then(function() {
            $this.isLoading = false;
            $this.isSuccess = true;
            $this.goTo("complete");
          })
          .catch(function(error) {
            console.log(error);
            $this.isLoading = false;
            alert(
              "Sorry, there was a problem sending your message. Please try again later."
            );
          });
      }
    }
  },
  created: function() {
    window.addEventListener("scroll", noscroll);
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", noscroll);
  }
};
</script>

<style lang="scss">
#setup-hfa-portal {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: -8px -8px 0;

  @media (min-width: $desktop) {
    margin-left: -50px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 300ms ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .radio {
    position: relative;
    font-size: 1.1em;
    cursor: pointer;

    input {
      display: none;
    }
    .square {
      display: inline-block;
      width: 1em;
      height: 1em;
      border: 1px solid;
      vertical-align: middle;
      margin-right: 5px;
      background-color: #fff;
    }
    input:checked ~ .square {
      background-color: $yellow;
    }
  }

  .step {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transition: all 300ms ease-in-out;
    transform: translateY(100%);
    background-color: #fff;

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 4em);
      font-size: 1.4vw;

      .item {
        margin: 10vh 20px;

        @media (min-width: $desktop) {
          margin: 10vh 0;
        }

        > strong {
          font-family: "Fjalla One", sans-serif;
          font-weight: 400;
          font-size: 1.1em;
          display: block;
          margin-bottom: 10px;

          a {
            text-decoration: underline;
          }
        }
        .button {
          display: block;
        }
      }
    }

    &#licensing-needs {
      z-index: 1;
      transform: translateY(0%);
    }
    &#songfile-account,
    &#license-owner {
      z-index: 2;
    }
    &#complete {
      z-index: 3;
    }
    &.active {
      transform: translateY(0%);
    }

    &#licensing-needs {
      ul {
        li {
          margin: 10px 0;
        }
      }
    }

    &#license-owner {
      overflow: auto;

      .body {
        display: block;
        overflow: auto;
        height: calc(100vh - 65px);
        font-size: 1.2vw;
        padding: 0 0 20px;

        @media (min-width: $desktop) {
          height: auto;
          display: flex;
          justify-content: flex-start;
        }

        .loading {
          position: absolute;
          background-color: rgba(255, 255, 255, 0.9);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          z-index: 1;
          font-size: 1.5em;

          img {
            width: 100px;
          }
        }

        .buttons {
          margin: 10px 8px 0;
          width: calc(100% - 16px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .button {
            font-size: 1em;
            margin-bottom: 10px;
            cursor: pointer;
          }

          @media (min-width: $desktop) {
            margin: 50px 0 0;
            width: 80%;
            flex-direction: row;

            .button {
              font-size: 1.4em;
              margin-bottom: 0;
            }
          }
        }
        .type {
          width: 100%;
          text-align: center;
          margin: 10px 0 0;
          font-size: 1.1em;
          font-weight: 900;

          @media (min-width: $desktop) {
            margin: 50px 0 0;
            font-size: 1.4em;
          }
        }
        form {
          display: flex;
          flex-wrap: nowrap;
          position: realtive;
          flex-direction: column;
          align-items: center;

          .column {
            width: 95%;
          }
          .submit {
            width: 100%;
            margin-top: 50px;
            text-align: center;

            .button {
              width: 300px;
              font-size: 1.4em;
            }
          }

          @media (min-width: $desktop) {
            flex-direction: row;
            flex-wrap: wrap;
            width: 90%;
            margin-top: 20px;
            justify-content: space-around;
            align-items: flex-start;

            .column {
              width: 47%;
            }
          }
        }
      }
    }
    &#songfile-account {
      .body {
        .item {
          text-align: center;
        }
        .button {
          font-size: 1.5em;
        }
      }
    }
    &#complete {
      .body {
        margin: 8px;

        .message {
          font-size: 1.3em;
          margin-bottom: 30px;
          text-align: center;
        }
        .button {
          font-size: 1.2em;
        }
      }
    }
  }
}
</style>
