<template>
  <div class="header">
    <a class="back button default" @click="$emit('goBack')">
      &lt; Back
    </a>
    <span>
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FormHeader",
  props: ["title"]
};
</script>

<style lang="scss">
.header {
  background-color: $blue;
  padding: 0.3em;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  font-size: 1.2vw;

  .back {
    padding: 0.2em;
    float: right;
    font-size: 0.85em;
    font-weight: 900;
    font-family: "Antic Slab", serif;
  }

  @media (min-width: $desktop) {
    padding: 1em;
  }
}
</style>
